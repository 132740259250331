import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import { PaymentSourceType } from '../payment_sources_prop_types';
import RemovePaymentSource from './remove_payment_method';
import ExpandedText from '../../helpers/expanded_text';
import AppGeneralPopup from '../../app_general_popup';
import CreditCardImg from '../../blue_snap/credit_card_img';
import { appGeneralPopupTheme } from '../../config/const';
import {
  formatCardName, hideEmailAdress, getAppTheme, getIcon
} from '../../config/utils';

const payPalImg = require('../img/paypal_icon.svg');

const requireContext = require.context('./img', false, /\.svg$/);

const renderSourceIcon = ({ kind, type }) => {
  if (kind === 'cc') return <CreditCardImg type={ type } className={ classes.sourceIcon } />;
  if (kind === 'ap') return <div className={ classes.applePayButton } />;
  return <img src={ payPalImg } alt="" className={ classes.sourceIcon } />;
};

const renderRemoveSourceItem = ({ isDefault, existing, onRemove }) => {
  if (isDefault && existing.length > 1) return null;
  const appTheme = getAppTheme();
  const deleteIcon = getIcon({ iconName: 'delete_icon', requireContext, appTheme });
  return (
    <button type="button" className={ classes.removeItem } onClick={ onRemove }>
      <img src={ deleteIcon } alt="" className={ classes.deleteIcon } />
    </button>
  );
};

const getSourceType = ({ kind, type }) => {
  if (kind === 'ap') return 'Apple Pay';
  if (type) return formatCardName(type);
  return 'PayPal';
};

const renderSourceDetailsHeader = ({
  kind, type, isDefault, existing, onRemove
}) => (
  <div className={ classes.sourceDetailsHeader }>
    <div className={ classes.sourceName }>
      { renderSourceIcon({ kind, type }) }
      { getSourceType({ kind, type }) }
    </div>
    { renderRemoveSourceItem({ isDefault, existing, onRemove }) }
  </div>
);

const renderDetails = ({
  kind, last4, exp, paypalBillingAgreementEmail, fullName
}) => {
  if (kind === 'ap') return null;
  if (kind === 'cc') {
    return (
      <div className={ classes.ccDetailsContainer }>
        <div className={ classes.ccLast4 }>
          {'* * * * '}
          { last4 }
        </div>
        <div className={ classes.ccDetails }>
          {/* <span>FULL NAME</span> */}
          <span>{ fullName }</span>
          <span>{ `EXP: ${ exp }` }</span>
        </div>
      </div>
    );
  }
  return (
    <div className={ classes.ppDetails }>
      <ExpandedText customClasses={ { text: classes.ellipsisPayPalName } } ellipsis>
        { hideEmailAdress(paypalBillingAgreementEmail) || 'PayPal'}
      </ExpandedText>
    </div>
  );
};

function PaymentMethodDetails({
  kind, context, isDefault, id, removeSource, onMakeDefault, existing, fullName
}) {
  const { t } = useTranslation();
  const [removing, setRemoving] = useState(false);
  const {
    type, last4, exp, paypalBillingAgreementEmail
  } = context || {};

  const onRemove = () => {
    setRemoving(true);
  };

  const onCancelRemove = () => {
    setRemoving(false);
  };

  const onConfirmRemove = () => {
    removeSource(id);
  };

  const renderDefaultSection = () => {
    if (isDefault) {
      return (
        <div className={ classes.isDefault }>
          { t('purchase.default')}
        </div>
      );
    }
    return (
      <button
        type="button"
        className={ classes.setDefaultButton }
        onClick={ onMakeDefault }
      >
        { t('purchase.set_default') }
      </button>
    );
  };

  const renderPaymentSourceContainer = () => (
    <div className={ classes.container }>
      { renderSourceDetailsHeader({
        kind, type, isDefault, existing, onRemove
      }) }
      { renderDetails({
        kind, last4, exp, paypalBillingAgreementEmail, fullName
      }) }
      { renderDefaultSection() }
    </div>
  );

  const removePaymentMethodPopup = () => {
    if (!removing) return null;
    return (
      <AppGeneralPopup theme={ appGeneralPopupTheme.CENTERED }>
        <RemovePaymentSource
          onConfirmRemove={ onConfirmRemove }
          onCancelRemove={ onCancelRemove }
        />
      </AppGeneralPopup>
    );
  };

  const renderScreenThemeContainer = () => (
    <div className={ classes.rootWallet }>
      { renderPaymentSourceContainer() }
      { removePaymentMethodPopup() }
    </div>
  );

  return renderScreenThemeContainer();
}

PaymentMethodDetails.propTypes = {
  ...PaymentSourceType,
  removeSource: PropTypes.func.isRequired,
  onMakeDefault: PropTypes.func.isRequired,
  clickSource: PropTypes.string
};

PaymentMethodDetails.defaultProps = {
  clickSource: null
};

export default PaymentMethodDetails;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isAndroid } from 'react-device-detect';
import classnames from 'classnames';
import classes from './classes.module.scss';
import { messageToAppKeys } from '../../message_to_app_handler';
import UsePreviousValue, { compareObjects, fromMobileApp, isOldMobileApp } from '../../config/utils';
import { paymentSourcesTheme } from '../../config/const';

const closeImg = require('./img/close_img');

const isPurchaseAndNotAndroid = (theme) =>  theme === paymentSourcesTheme.purchase && !isAndroid;

const sentMessageToAppIfNeed = ({
  titleData, setMessageToAppData, theme
}) => {
  if (Object.keys(titleData).length > 0) {
    if (isPurchaseAndNotAndroid(theme)) return;
    const { backArrowHandler, titleText } = titleData;
    const sentToAppMessage = {
      [messageToAppKeys.action]: messageToAppKeys.crossPlatformNavigation,
      [messageToAppKeys.params]: {
        [messageToAppKeys.title]: titleText,
        [messageToAppKeys.isBackAvailable]: !!backArrowHandler
      }
    };
    setMessageToAppData({ sentToAppMessage });
  }
};

const getAddCardHeaderBtnContainerClassNeme = ({ loading }) => {
  if (loading) return classnames(classes.addCardHeaderBtnContainerContent, classes.addCardHeaderBtnContainerContentSkeleton);
  return classes.addCardHeaderBtnContainerContent;
};

const renderAddCardHeaderBtn = ({ addCardHeaderBtnHolder, loading }) => {
  if (!addCardHeaderBtnHolder) return null;
  return (
    <div className={ classes.addCardHeaderBtnContainer }>
      <div className={ getAddCardHeaderBtnContainerClassNeme({ loading }) }>
        { addCardHeaderBtnHolder() }
      </div>
    </div>
  );
};

const renderTitleText = ({ titleText, titleTextStyle }) => {
  if (!titleText) return <div className={ classes.titlePaymentMethods } />;
  return (
    <div className={ classes.titlePaymentMethods } style={ titleTextStyle }>
      { titleText }
    </div>
  );
};

const getBackArrowClassNeme = ({ loading }) => {
  if (loading) return classnames(classes.backArrow, classes.skeleton);
  return classes.backArrow;
};

const renderBackArrow = ({ backArrowHandler, theme, loading }) => {
  if (!backArrowHandler
    || (fromMobileApp && theme === paymentSourcesTheme.wallet && !isOldMobileApp)) {
    return <div className={ classes.backArrowContainer } />;
  }
  return (
    <div className={ classes.backArrowContainer }>
      <button
        type="button"
        className={ getBackArrowClassNeme({ loading }) }
        onClick={ backArrowHandler }
      />
    </div>
  );
};

const getcloseBtnClassNeme = ({ loading }) => {
  if (loading) return classnames(classes.closeBtn, classes.skeleton);
  return classes.closeBtn;
};

const renderCloseBtn = ({ closeBtnHandler, loading }) => {
  if (!closeBtnHandler) return null;
  return (
    <div className={ classes.closeBtncontainer }>
      <button
        type="button"
        className={ getcloseBtnClassNeme({ loading }) }
        onClick={ closeBtnHandler }
      >
        <img src={ closeImg } className={ classes.closeImg } alt="" />
      </button>
    </div>
  );
};

function PaymentMethodsListTitle({
  titleData, theme, receivedMessageFromApp, setMessageToAppData, loading
}) {
  const [titleDataState, setTitleDataState] = useState(null);
  const {
    backArrowHandler, closeBtnHandler, titleText, titleTextStyle, addCardHeaderBtnHolder
  } = titleData;

  useEffect(() => {
    if (!compareObjects(titleData, titleDataState)) setTitleDataState(titleData);
    const prevTitleData = <UsePreviousValue value={ titleDataState } />;
    if (titleData && !compareObjects(titleData, prevTitleData.props.value) && setMessageToAppData) {
      sentMessageToAppIfNeed({
        titleData, setMessageToAppData, prevTitleData, theme
      });
    }
  }, [fromMobileApp, titleData]);

  useEffect(() => {
    if (receivedMessageFromApp) {
      if (receivedMessageFromApp[messageToAppKeys.action] === messageToAppKeys.nativeBackPressed
        && backArrowHandler) {
        backArrowHandler();
        setMessageToAppData({ receivedMessageFromApp: {} });
      }
    }
  }, [receivedMessageFromApp]);

  const renderPaymentMethodsListTitleContext = () => (
    <div className={ classes[theme] }>
      <div className={ classes.titleRowItems }>
        { renderBackArrow({ backArrowHandler, theme, loading }) }
        { renderTitleText({ titleText, titleTextStyle }) }
        { renderAddCardHeaderBtn({ addCardHeaderBtnHolder, loading }) }
        { renderCloseBtn({ closeBtnHandler }) }
      </div>
    </div>
  );

  const renderPaymentMethodsListTitle = () => renderPaymentMethodsListTitleContext();

  return renderPaymentMethodsListTitle();
}

PaymentMethodsListTitle.propTypes = {
  titleData: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  receivedMessageFromApp: PropTypes.object,
  setMessageToAppData: PropTypes.func,
  loading: PropTypes.bool
};

PaymentMethodsListTitle.defaultProps = {
  receivedMessageFromApp: {},
  setMessageToAppData: null,
  loading: null
};

export default PaymentMethodsListTitle;

import React from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '../config/utils';

const requireContext = require.context('./img/cards', false, /\.svg$/);

function CreditCardImg({
  type, className, style, large
}) {
  return (
    <img
      src={ getIcon({ iconName: type.toLowerCase(), requireContext }) }
      alt=""
      className={ className }
      style={ style }
      width={ large ? 71 : null }
    />
  );
}
CreditCardImg.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  large: PropTypes.bool
};

CreditCardImg.defaultProps = {
  className: '',
  style: {},
  type: 'UNKNOWN',
  large: false
};
export default CreditCardImg;

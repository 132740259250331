import PropTypes from 'prop-types';

export const PaymentSourceType = {
  kind: PropTypes.string.isRequired,
  humanKind: PropTypes.string.isRequired,
  expired: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  enabled: PropTypes.bool.isRequired,
  isDuplicate: PropTypes.bool.isRequired,
  reauthorizeRequired: PropTypes.bool.isRequired,
  canBeUsed: PropTypes.bool.isRequired,
  context: PropTypes.shape({
    type: PropTypes.string,
    last4: PropTypes.string,
    exp: PropTypes.string,
    paypalBillingAgreementEmail: PropTypes.string
  }),
  fullName: PropTypes.string
};

export const PaymentSourcesType = PropTypes.arrayOf(PropTypes.shape(PaymentSourceType));

export const AvailablePaymentSourceType = {
  kind: PropTypes.string.isRequired,
  humanKind: PropTypes.string.isRequired,
  preferred: PropTypes.bool.isRequired
};

export const AvailablePaymentSourcesType = PropTypes.arrayOf(
  PropTypes.shape(AvailablePaymentSourceType)
);

export const PaymentSourcesListType = {
  available: PropTypes.arrayOf(
    PropTypes.shape(AvailablePaymentSourceType)
  ),
  current: PropTypes.shape(PaymentSourceType),
  editingId: PropTypes.number,
  error: PropTypes.string,
  existing: PropTypes.arrayOf(PropTypes.shape(PaymentSourceType)),
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  managingSource: PropTypes.shape(PaymentSourceType)
};

export const PaymentOptionSlimType = {
  kind: PropTypes.oneOf(['cc', 'pp', 'iap', 'ap']).isRequired,
  context: PropTypes.oneOfType([
    PropTypes.shape({
      hasPaypalBillingAgreement: PropTypes.bool,
      paypalBillingAgreementEmail: PropTypes.bool
    }),
    PropTypes.shape({
      last4: PropTypes.string,
      type: PropTypes.string,
      exp: PropTypes.string
    })
  ])
};

export const PaymentOptionTypes = {
  ...PaymentOptionSlimType,
  id: PropTypes.number,
  humanKind: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  preferred: PropTypes.bool,
  extraParams: PropTypes.string,
  adding: PropTypes.bool
};

export const DefaultPaymentOptionValues = {
  id: null,
  errorText: null,
  preferred: false,
  extraParams: null,
  adding: false,
  context: null
};

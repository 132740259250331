import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';

function RemovePaymentSource({
  onCancelRemove, onConfirmRemove
}) {
  const { t } = useTranslation();

  const renderRemoveButtonsSection = () => (
    <div className={ classes.removeButtons }>
      <button
        type="button"
        className={ classes.cancelRemove }
        onClick={ onCancelRemove }
      >
        { t('purchase.cancel') }
      </button>
      <button
        type="button"
        className={ classes.confirmRemove }
        onClick={ onConfirmRemove }
      >
        { t('purchase.yes_delete_it')}
      </button>
    </div>
  );

  const renderRemovingContainer = () => (
    <div className={ classes.removeContainer }>
      <div className={ classes.removeTitle }>
        { t('purchase.delete_payment_method')}
      </div>
      <div className={ classes.removeText }>
        { t('purchase.are_you_sure_you_want_to_delete_this_payment_method') }
      </div>
      { renderRemoveButtonsSection() }
    </div>
  );

  return (
    renderRemovingContainer()
  );
}

RemovePaymentSource.propTypes = {
  onCancelRemove: PropTypes.func.isRequired,
  onConfirmRemove: PropTypes.func.isRequired
};

export default RemovePaymentSource;

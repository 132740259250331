import { MarketingBanner } from '@barges/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getDataFromStorage, getXfmFreeSetupMin, isPrerenderMode, matchBannerRoutes
} from '../../../config/util';
import MarketingImage from '../../../project_items/marketing_img/marketing_image';
import env from '../../../config/variables';

import '@barges/scss/lib/title.css';
import '@barges/scss/lib/marketing-banner.css';

import { toCamelCase } from '../../../../common/config/utils';
import { showBannerRoutes } from '../../../config/app_routes';

const {
  DEFAULT_XFM_CREDITS, XFM_PROGRAM_ACTIVE
} = env;

const TopBanner = ({
  userBonusInfos = [], userXfmProgram = null, userId, anonymousUserBonusInfos
}) => {
  const { t } = useTranslation();
  const isXFMActive = () => XFM_PROGRAM_ACTIVE && XFM_PROGRAM_ACTIVE.toLowerCase() === 'true';

  const shouldShowXFMBanner = () => {
    if (isPrerenderMode()) return false;
    if (!isXFMActive()) return false;
    if (userId) {
      return !!userXfmProgram?.credits;
    }
    const wasLoggedIn = !!getDataFromStorage('WAS_LOGIN');
    return !wasLoggedIn;
  };

  const freeMinutesText = () => {
    const minutes = getXfmFreeSetupMin();
    const advisors = userXfmProgram?.credits ?? DEFAULT_XFM_CREDITS ?? 3;

    const pluralMinute = minutes > 1 ? t('enjoy_free_minutes.minutes') : t('enjoy_free_minutes.minute');
    const pluralAdvisor = advisors > 1 ? t('enjoy_free_minutes.advisors') : t('enjoy_free_minutes.advisor');

    return (
      <div>
        { minutes }
        &nbsp;
        { t('enjoy_free_minutes.free') }
        &nbsp;
        { pluralMinute }
        <br />
        { t('enjoy_free_minutes.with') }
        &nbsp;
        { advisors }
        &nbsp;
        { pluralAdvisor }
        !
      </div>
    );
  };

  const getActiveOffers = () => {
    if (userId) return userBonusInfos;
    if (anonymousUserBonusInfos) return anonymousUserBonusInfos;
    return window.bonus_infos ?? [];
  };

  const generateMarketingBannerArray = () => {
    if (!matchBannerRoutes(showBannerRoutes)) return [];
    const activeOffers = getActiveOffers();
    let offers = [];
    if (activeOffers?.length > 0) {
      const offer = toCamelCase(activeOffers[0]);
      offers = [{ title: offer.specialOffer ?? '', subtitle: offer.bonusText  ?? '' }];
    }
    if (shouldShowXFMBanner()) {
      offers.push({ title: freeMinutesText() });
    }
    return offers;
  };

  const offers = generateMarketingBannerArray();

  if (!offers?.length) return null;

  return (
    <MarketingBanner
      offers={ offers }
      offerIcon={ <MarketingImage /> }
    />
  );
};

TopBanner.propTypes = {
  userBonusInfos: PropTypes.arrayOf(PropTypes.shape({
    specialOffer: PropTypes.string,
    bonusText: PropTypes.string
  })),
  anonymousUserBonusInfos: PropTypes.array,
  userXfmProgram: PropTypes.shape({
    credits: PropTypes.number
  }),
  userId: PropTypes.number
};

TopBanner.defaultProps = {
  userBonusInfos: [],
  anonymousUserBonusInfos: null,
  userXfmProgram: null,
  userId: null
};

export default TopBanner;

import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';

function Toast({ toastText, show, bottomPlacement }) {
  return (
    <div
      className={ show ? classes.toastContainer : classes.toastContainerHiden }
      style={ { '--bottom': bottomPlacement || '60px' } }
    >
      <div className={ classes.toast }>
        <div className={ classes.toastMessage }>
          {toastText}
        </div>
      </div>
    </div>
  );
}

Toast.propTypes = {
  toastText: PropTypes.string.isRequired,
  show: PropTypes.bool,
  bottomPlacement: PropTypes.string
};

Toast.defaultProps = {
  show: false,
  bottomPlacement: null
};

export default Toast;

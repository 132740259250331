import { advisorUpdated, advisorsListUpdated, userUpdated } from '../actions/shared_actions';
import { updateSlugIfNeed } from '../../common/config/utils';

export const refreshReducersFromResponse = (res, { ignoreUserUpdate = false } = {}) => {
  const { user, advisor, advisors } = res;
  if (!ignoreUserUpdate && user) {
    window.store.dispatch(userUpdated(user));
  }
  if (advisor) {
    window.store.dispatch(advisorUpdated(updateSlugIfNeed({ item: advisor })));
  }
  if (advisors) {
    window.store.dispatch(advisorsListUpdated(updateSlugIfNeed({ item: advisors })));
  }
};

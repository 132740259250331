const pgLogoIcon = require('./img/pg_logo.png');
const kasambaLogoIcon = require('./img/kasamba_logo_icon.png');

export const appLogo = () => {
  switch (process.env.APP_CONTEXTS) {
    case 'ka':
      return kasambaLogoIcon;
    default:
      return pgLogoIcon;
  }
};

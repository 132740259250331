import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import classes from './classes.module.scss';
import { paymentSourcesTheme } from '../../config/const';
import { getIcon, getAppTheme, isKA } from '../../config/utils';
import AddApplePayOption from '../../payment_options/apple_pay/add_apple_pay_option';
import { applePayIsSupported } from '../../purchase_details_common/purchase_details_common_utils';

const requireContext = require.context('./img', false, /\.svg$/);

const sideManuTitle = ({ existing, t }) => {
  if (existing && existing.length < 1) return t('purchase.add_a_new_payment_method');
  return t('purchase.add_another_payment_method');
};

const addSourceImg = ({ theme, kind }) => {
  const appTheme = getAppTheme();
  if (theme === paymentSourcesTheme.wallet) {
    return getIcon({
      iconName: 'add_payment', requireContext, forAppContext: true, appTheme
    });
  }
  if (kind === 'pp') {
    return getIcon({
      iconName: 'add_pp', requireContext, forAppContext: true, appTheme
    });
  }
  if (kind === 'iap') {
    return getIcon({
      iconName: 'add_iap', requireContext, forAppContext: true, appTheme
    });
  }
  if (kind === 'ap') {
    return getIcon({
      iconName: 'add_ap', requireContext, forAppContext: true, appTheme
    });
  }
  return getIcon({
    iconName: 'add_cc', requireContext, forAppContext: true, appTheme
  });
};

const getAddButtonText = ({
  theme, existing, kind, t
}) => {
  if (theme === paymentSourcesTheme.wallet) return sideManuTitle({ existing, t });
  return t(`purchase.add_${ kind }`);
};

const getAddButtonClasses = (loading) => {
  if (loading) return classnames(classes.addButton, classes.skeleton);
  return classes.addButton;
};

const getAddButtonId = ({ kind }) => {
  if (kind === 'iap') return 'in_app_purchase';
  if (kind === 'cc') return 'add_new_cc';
  if (kind === 'ap') return 'add_new_ap';
  return 'continue_with_paypal';
};

const getAddButtonStyle = ({ theme }) => {
  if (isKA() && theme === paymentSourcesTheme.wallet) {
    return {
      backgroundColor: 'var(--app-color)', color: 'var(--app-white)', boxShadow: 'unset', border: 'unset'
    };
  }
  return {};
};

function AddPaymentMethod({
  kind, addSource, theme, existing, loading
}) {
  const { t } = useTranslation();

  const onAdd = () => {
    if (addSource) addSource(kind);
  };

  const renderAddAPPaymentMethod = () => {
    if (loading || !applePayIsSupported()) return null;
    return <AddApplePayOption topPadding={ 8 } />;
  };

  const renderAddPaymentMethod = () => (
    <div className={ classes[theme] }>
      <button
        id={ getAddButtonId({ kind }) }
        type="button"
        onClick={ onAdd }
        className={ getAddButtonClasses(loading) }
        style={ { ...getAddButtonStyle({ theme }) } }
      >
        <img
          src={ addSourceImg({ theme, kind }) }
          alt=""
          className={ classes.addButtonImg }
        />
        <div className={ classes.popupAddButtonText }>
          { getAddButtonText({
            theme, existing, kind, t
          }) }
        </div>
      </button>
    </div>
  );

  return kind === 'ap' ? renderAddAPPaymentMethod() : renderAddPaymentMethod();
}

AddPaymentMethod.propTypes = {
  kind: PropTypes.string.isRequired,
  addSource: PropTypes.func,
  existing: PropTypes.array,
  theme: PropTypes.oneOf([paymentSourcesTheme.purchase, paymentSourcesTheme.wallet])
};

AddPaymentMethod.defaultProps = {
  theme: paymentSourcesTheme.wallet,
  addSource: null,
  existing: []
};

export default AddPaymentMethod;

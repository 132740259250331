import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { asyncLoadScript } from '../../config/utils';
import { addSource } from '../../../app/actions/payment_sources';

const AddApplePayOption = ({ topPadding }) => {
  const [loaded, setLoaded] = useState(false);

  const handleApplePayClick = () => {
    window.store.dispatch(addSource('ap'));
  };

  useEffect(() => {
    const applePayButton = document.querySelector('apple-pay-button');
    if (applePayButton) {
      applePayButton.addEventListener('click', handleApplePayClick);
    }
    return () => {
      if (applePayButton) {
        applePayButton.removeEventListener('click', handleApplePayClick);
      }
    };
  }, [loaded]);

  useEffect(() => {
    asyncLoadScript('https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js')
      .then(() => {
        setLoaded(true);
      });
  }, []);

  if (!loaded) {
    return null;
  }

  const buttonStyle = () => {
    const search = (queryString.parse(window.location.search) || {});
    return search?.theme === 'dark' ? 'white' : 'white-outline';
  };

  return (
    <>
      <style>
        {`
        apple-pay-button {
          --apple-pay-button-height: 64px;
          --apple-pay-button-border-radius: 6px;
          --apple-pay-button-padding: 15px 0px;
          --apple-pay-button-box-sizing: border-box;
          width: 100%;
          margin-top: ${ topPadding }px;
        }
      `}
      </style>
      <apple-pay-button buttonstyle={ buttonStyle() } type="pay" locale="en-US" />
    </>
  );
};

export default AddApplePayOption;

AddApplePayOption.propTypes = {
  topPadding: PropTypes.number.isRequired
};

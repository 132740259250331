/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect, useRef, useState, useCallback
} from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { appGeneralPopupTheme } from '../config/const';
import {
  hideBodyScroll, unlockBodyScroll, isMobile, isKA
} from '../config/utils';
import { appLogo } from '../../app/logo/logo';

const backArrow = require('./img/back_arrow.svg');
const closeIconPG = require('../../app/components/created_chat/img/ic_chat_hangup_pg.svg');
const closeIconKA = require('../../app/components/created_chat/img/ic_chat_hangup_ka.svg');

const renderBackArrow = ({ backArrowAction, backCustomAction }) => (
  backArrowAction || backCustomAction ? (
    <div className = { classes.arrowDiv } onClick={ backCustomAction || backArrowAction }>
      <img src={ backArrow } alt="" className={ classes.arrow } />
    </div>
  ) : <div />
);

const renderRightItem = ({ rightCustomItem, closeButtonAction }) => {
  if (rightCustomItem) return rightCustomItem;
  return (
    closeButtonAction ? (
      <button className={ classes.closeBtn } onClick={ closeButtonAction } type="button">
        <img src={ isKA() ? closeIconKA : closeIconPG } alt="" className={ classes.closeButtonImg } />
      </button>
    ) : <div />
  );
};

const shouldRenderHeader = ({
  backArrowAction, closeButtonAction, rightCustomItem, backCustomAction
}) => backArrowAction || closeButtonAction || !!rightCustomItem || !!backCustomAction;

const renderTopHeader = ({
  customTopHeader, backArrowAction, backCustomAction, title, rightCustomItem, closeButtonAction
}) => {
  if (customTopHeader) return customTopHeader;
  if (!shouldRenderHeader({
    backArrowAction, closeButtonAction, rightCustomItem, backCustomAction
  })) return null;
  return (
    <div className={ classes.topHeaderConteiner }>
      { renderBackArrow({ backArrowAction, backCustomAction }) }
      { title }
      { renderRightItem({ rightCustomItem, closeButtonAction }) }
    </div>
  );
};

const handleSetEmptyDiv = ({ emptyHeight, setEmptyDivHeight }) => {
  if (isMobile()) {
    let updatedHeight = emptyHeight;
    if (emptyHeight < 0 && emptyHeight < 150) updatedHeight = 150;
    setEmptyDivHeight(Math.abs(updatedHeight));
    return;
  }
  if (emptyHeight < 0) {
    setEmptyDivHeight(Math.abs(emptyHeight));
    return;
  }
  setEmptyDivHeight(0);
};

const getBottomPopoverEmptyDivHeight = ({
  appGeneralPopupContentRef, appGeneralPopupOverlayContainerRef, setEmptyDivHeight
}) => {
  const appGeneralPopupContent = appGeneralPopupContentRef.current;
  const appGeneralPopupOverlayContainer = appGeneralPopupOverlayContainerRef;
  if (!appGeneralPopupContent || !appGeneralPopupOverlayContainer) return;
  const appGeneralPopupOverlayContainerPosition = appGeneralPopupOverlayContainer.getBoundingClientRect();
  const appGeneralPopupContentPosition = appGeneralPopupContent.getBoundingClientRect();
  const emptyHeight = appGeneralPopupOverlayContainerPosition.height - appGeneralPopupContentPosition.height;
  handleSetEmptyDiv({ emptyHeight, setEmptyDivHeight });
};

const getTopHeaderConteinerStyle = ({ withLogo }) => {
  if (withLogo) return { display: 'flex' };
  return {};
};

const renderTopHeaderContainer = ({ logoImg, withLogo }) => {
  if (logoImg) {
    return <div className={ classes.topHeaderConteinerLogoContainer } style={ { display: 'flex' } }>{ logoImg }</div>;
  }
  return (
    <div
      className={ classes.topHeaderConteinerLogoContainer }
      style={ getTopHeaderConteinerStyle({ withLogo }) }
    >
      <img src={ appLogo() } alt="" className={ classes.topHeaderConteinerLogo } style={ getTopHeaderConteinerStyle({ withLogo }) } />
    </div>
  );
};

const getOverlayContainerClickHandler = (closable) => {
  if (closable) closable();
};

const getGeneralContainerClickHandler = ({ closable, e }) => {
  if (closable) e.stopPropagation();
};

const getAppGeneralPopupOnBodyStyle = (visible) => {
  if (visible) return { display: 'flex' };
  return { display: 'none' };
};

const openOnAppRootHandler = ({ appGeneralPopup, needOpenOnAppRoot, visible }) => {
  if (appGeneralPopup && needOpenOnAppRoot && visible) {
    document.getElementById('appRoot').appendChild(appGeneralPopup);
    return (() => { appGeneralPopup.parentNode.removeChild(appGeneralPopup); });
  }
  return (() => {});
};

const resizeObserverHandler = ({ appGeneralPopupContent, handleResize }) => {
  if (appGeneralPopupContent) {
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(appGeneralPopupContent);
    return () => { resizeObserver.disconnect(); };
  }
  return () => {};
};

const bodyScrollHandler = ({ visible, handleResize }) => {
  if (visible) {
    hideBodyScroll();
    window.addEventListener('resize', handleResize, false);
  }
  if (!visible) {
    const overlayContainers = document.getElementsByClassName(`${ classes.overlayContainer }`);
    if (overlayContainers.length < 1) unlockBodyScroll();
    window.removeEventListener('resize', handleResize, false);
  }
};

const getAppGeneralPopupOverlayContainerStyle = ({ appGeneralPopupOverlayContainerRef }) => {
  const overlayContainers = document.getElementsByClassName(`${ classes.overlayContainer }`);
  if (overlayContainers.length > 1 && appGeneralPopupOverlayContainerRef !== overlayContainers[0]) return { position: 'absolute' };
  return {};
};

const renderPopupContentHandler = ({
  visible, emptyDivHeight, appGeneralPopupContentRef, logoImg, title, customTopHeader,
  backArrowAction, backCustomAction, rightCustomItem, closeButtonAction, children, withLogo,
  getOverlayContainerClick, getGeneralContainerClick, appGeneralPopupOverlayContainerRefHandler, appGeneralPopupOverlayContainerRef
}) => {
  if (!visible) return null;
  return (
    <div
      id="appGeneralPopupOverlayContainer"
      className={ classes.overlayContainer }
      onClick={ getOverlayContainerClick }
      ref={ appGeneralPopupOverlayContainerRefHandler }
      style={ { ...getAppGeneralPopupOverlayContainerStyle({ appGeneralPopupOverlayContainerRef }) } }
    >
      <div id="generalContainer" className={ classes.generalContainer } onClick={ getGeneralContainerClick }>
        <div className={ classes.generalHolder }>
          <div className={ classes.emptyDiv } onClick={ getOverlayContainerClick } style={ { '--height': `${ emptyDivHeight }px` } } />
          <div ref={ appGeneralPopupContentRef }>
            { renderTopHeaderContainer({ logoImg, withLogo }) }
            <div className={ classes.popupContainer }>
              { renderTopHeader({
                customTopHeader, backArrowAction, backCustomAction,
                title, rightCustomItem, closeButtonAction
              }) }
              { children }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function AppGeneralPopup({
  children, backArrowAction, closeButtonAction, rightCustomItem, backCustomAction, theme, visible,
  title, customTopHeader, closable, needOpenOnAppRoot, logoImg, withLogo
}) {
  const appGeneralPopupRef = useRef();
  const appGeneralPopupContentRef = useRef();
  const [appGeneralPopupOverlayContainerRef, setAppGeneralPopupOverlayContainerRef] = useState(null);
  const [emptyDivHeight, setEmptyDivHeight] = useState(null);

  const handleResize = () => {
    getBottomPopoverEmptyDivHeight({
      appGeneralPopupContentRef, appGeneralPopupOverlayContainerRef, setEmptyDivHeight
    });
  };

  useEffect(() => {
    if (visible === null) return () => {};
    if (visible) {
      bodyScrollHandler({ visible, handleResize });
      return () => { bodyScrollHandler({ visible: !visible, handleResize }); };
    }
    return () => {};
  }, [visible]);

  useEffect(() => {
    const appGeneralPopupContent = appGeneralPopupContentRef.current;
    const cleanerFunction = resizeObserverHandler({ appGeneralPopupContent, handleResize });
    return () => { cleanerFunction(); };
  }, [appGeneralPopupOverlayContainerRef, appGeneralPopupContentRef.current]);

  const appGeneralPopupOverlayContainerRefHandler = useCallback((appGeneralPopupOverlayContainerNode) => {
    setAppGeneralPopupOverlayContainerRef(appGeneralPopupOverlayContainerNode);
  }, []);

  const getOverlayContainerClick = () => { getOverlayContainerClickHandler(closable); };

  const getGeneralContainerClick = (e) => { getGeneralContainerClickHandler({ closable, e }); };

  const renderPopupContent = () => (
    renderPopupContentHandler({
      visible, emptyDivHeight, appGeneralPopupContentRef,
      logoImg, title, customTopHeader, backArrowAction, backCustomAction, rightCustomItem,
      closeButtonAction, children, getOverlayContainerClick, getGeneralContainerClick, withLogo,
      appGeneralPopupOverlayContainerRefHandler, appGeneralPopupOverlayContainerRef
    })
  );

  useEffect(() => {
    const appGeneralPopup = appGeneralPopupRef.current;
    openOnAppRootHandler({ appGeneralPopup, needOpenOnAppRoot, visible });
  }, [appGeneralPopupRef.current, visible]);

  const renderAppGeneralPopupOnBody = () => (
    <div
      suppressHydrationWarning
      className={ classes[theme] }
      style={ { ...getAppGeneralPopupOnBodyStyle(visible) } }
      ref={ appGeneralPopupRef }
    >
      { renderPopupContent() }
    </div>
  );

  const renderAppGeneralPopupRegular = () => {
    if (!visible) return null;
    return (
      <div className={ classes[theme] } ref={ appGeneralPopupRef }>{ renderPopupContent() }</div>
    );
  };

  const renderAppGeneralPopup = () => {
    if (needOpenOnAppRoot) return renderAppGeneralPopupOnBody();
    return renderAppGeneralPopupRegular();
  };

  return renderAppGeneralPopup();
}

AppGeneralPopup.propTypes = {
  children: PropTypes.node,
  backArrowAction: PropTypes.func,
  closeButtonAction: PropTypes.func,
  rightCustomItem: PropTypes.node,
  backCustomAction: PropTypes.func,
  theme: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.node,
  customTopHeader: PropTypes.node,
  closable: PropTypes.func,
  needOpenOnAppRoot: PropTypes.bool,
  logoImg: PropTypes.node,
  withLogo: PropTypes.bool
};

AppGeneralPopup.defaultProps = {
  children: null,
  backArrowAction: null,
  closeButtonAction: null,
  rightCustomItem: null,
  backCustomAction: null,
  theme: appGeneralPopupTheme.DEFAULT,
  visible: true,
  title: null,
  customTopHeader: null,
  closable: null,
  needOpenOnAppRoot: false,
  logoImg: null,
  withLogo: false
};

export default AppGeneralPopup;

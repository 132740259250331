import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import {
  tagIds, getBlueSnapFieldFocusStyle, renderError,
  getInputClasses, getLoading, canSave, renderLabel, getPlaceHolders, renderCreditCardImg,
  renderTooltipHandler, addCreditCardFormInputTypes, handleNameZipError
} from './add_credit_card_form_utils';
import { tooltipTypes } from './tooltips/tooltips';
import useGetewayLoader from './use_geteway_loader/use_geteway_loader';

const renderFraudFrame = (getewayFields) => {
  if (!getewayFields.fraudFrame) return null;
  return getewayFields.fraudFrame;
};

const AddCreditCardForm = ({
  setSavingBlueSnapCard, config, loading, setAddCreditCardFormStatusHandler, log, getConfig, submitCardDataHandler, blueSnapSettings
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [zip, setZip] = useState('');
  const [visibleTooltip, setVisibleTooltip] = useState(null);
  const [addCreditCardFormErrors, setAddCreditCardFormErrors] = useState({});

  const geteway = useGetewayLoader({
    config, log, loading, blueSnapSettings, t, setSavingBlueSnapCard, getConfig, submitCardDataHandler
  });

  const { getewayFields = {}, getewayStatus = {} } = geteway;
  const { fieldFocus, getewayErrors } = getewayStatus;

  const clearedErrors = (errorType) => {
    setAddCreditCardFormErrors(pendingAddCreditCardFormErrors => {
      const clearedErrorKeys = Object.keys(pendingAddCreditCardFormErrors)
        .filter((err) => err !== errorType);
      const clearedError = {};
      clearedErrorKeys.forEach((key) => { clearedError[key] = pendingAddCreditCardFormErrors[key]; });
      return { ...clearedError };
    });
  };

  const setAddCreditCardFormErrorsHandler = (value) => {
    setAddCreditCardFormErrors(pendingAddCreditCardFormErrors => ({ ...pendingAddCreditCardFormErrors, ...value }));
  };

  useEffect(() => {
    if (getewayErrors) {
      if (Object.keys(getewayErrors).length === 0) {
        clearedErrors('getewayErrors');
      } else {
        setAddCreditCardFormErrorsHandler({ getewayErrors: { ...getewayErrors } });
      }
    }
  }, [getewayErrors]);

  const getGetewayError = (tagId) => (addCreditCardFormErrors.getewayErrors || {})[tagId];

  const setNameValue = (event) => {
    setName(event.target.value);
    if (addCreditCardFormErrors.cName) { clearedErrors(addCreditCardFormInputTypes.cName); }
  };

  const setZipValue = (event) => {
    setZip(event.target.value);
    if (addCreditCardFormErrors.cZip) { clearedErrors(addCreditCardFormInputTypes.cZip); }
  };

  useEffect(() => {
    if (getewayStatus.addCardHandle) {
      const addCardFunction = () => {
        getewayStatus.addCardHandle({
          handleNameZipError, name, zip, setAddCreditCardFormErrorsHandler, addCreditCardFormInputTypes, isloading: loading, getewayItem: getewayStatus.getewayItem
        });
      };
      setAddCreditCardFormStatusHandler({ addCard: addCardFunction, canSaveCard: canSave({ name, zip, getewayErrors }) && !getLoading({ loading, setupComplete: getewayStatus.setupComplete }) });
    }
  }, [name, zip, getewayErrors, getewayStatus.addCardHandle, loading, config, getewayStatus.getewayItem, getewayStatus.setupComplete]);

  useEffect(() => {
    setAddCreditCardFormStatusHandler(getewayStatus);
  }, [getewayStatus.setupComplete, getewayStatus.addCardClicked]);

  const hideTooltip = () => {
    log('CC', 'on hide tooltip');
    setVisibleTooltip(null);
  };

  const showTooltip = (tooltipType) => {
    log('CC', `on show tooltip ${ tooltipType }`);
    setVisibleTooltip(tooltipType);
  };

  const renderTooltip = (tooltipType) => (
    renderTooltipHandler({
      tooltipType, blueSnapSettings, visibleTooltip, hideTooltip, showTooltip
    })
  );

  const nameOfCardInput  = (
    <div className={ classes.bluesnapInputHolder }>
      { renderLabel({
        inputLabels: blueSnapSettings.inputLabels, inputType: addCreditCardFormInputTypes.cName
      }) }
      <input
        id="nameOfCard"
        type="text"
        value={ name }
        className={ getInputClasses({ error: addCreditCardFormErrors.cName, loading: getLoading({ loading, setupComplete: getewayStatus.setupComplete }) }) }
        onChange={ setNameValue }
        placeholder={ getPlaceHolders({ placeHolders: blueSnapSettings.placeHolders, placeHolderType: addCreditCardFormInputTypes.cName }) }
        maxLength={ 30 }
      />
      { renderError(addCreditCardFormErrors.cName) }
    </div>
  );

  const cardNumberInput = () => (
    <div className={ classes.bluesnapInputHolder }>
      { renderLabel({ inputLabels: blueSnapSettings.inputLabels, inputType: addCreditCardFormInputTypes.ccn }) }
      <div
        className={ getInputClasses({ error: getGetewayError(tagIds.ccn), loading: getLoading({ loading, setupComplete: getewayStatus.setupComplete }) }) }
        style={ getBlueSnapFieldFocusStyle({
          fieldFocus, tagType: tagIds.ccn, error: getGetewayError(tagIds.ccn)
        }) }
      >
        { renderCreditCardImg({ blueSnapSettings, cardNameType: getewayStatus.cardNameType }) }
        { getewayFields.cardNumberInput }
      </div>
      { renderError(getGetewayError(tagIds.ccn)) }
    </div>
  );

  const dateSecurityInput = (
    <div className={ classes.expirationSecurityHolder }>
      <div className={ classes.expirationSecurityContaienr }>
        { renderLabel({ inputLabels: blueSnapSettings.inputLabels, inputType: addCreditCardFormInputTypes.exp }) }
        <div
          className={ getInputClasses({ error: getGetewayError(tagIds.exp), loading: getLoading({ loading, setupComplete: getewayStatus.setupComplete }) }) }
          style={ getBlueSnapFieldFocusStyle({
            fieldFocus, tagType: tagIds.exp, error: getGetewayError(tagIds.exp)
          }) }
        >
          { getewayFields.dateExpiredInput }
          {renderTooltip(tooltipTypes.exp)}
        </div>
        { renderError(getGetewayError(tagIds.exp)) }
      </div>
      <div style={ { width: '15px' } } />
      <div className={ classes.expirationSecurityContaienr }>
        { renderLabel({ inputLabels: blueSnapSettings.inputLabels, inputType: addCreditCardFormInputTypes.cvv }) }
        <div
          className={ getInputClasses({ error: getGetewayError(tagIds.cvv), loading: getLoading({ loading, setupComplete: getewayStatus.setupComplete }) }) }
          style={ getBlueSnapFieldFocusStyle({
            fieldFocus, tagType: tagIds.cvv, error: getGetewayError(tagIds.cvv)
          }) }
        >
          { getewayFields.securityInput }
          {renderTooltip(tooltipTypes.cvv)}
        </div>
        { renderError(getGetewayError(tagIds.cvv)) }
      </div>
    </div>
  );

  const zipPostalCodeInput = (
    <div className={ classes.bluesnapInputHolder }>
      { renderLabel({ inputLabels: blueSnapSettings.inputLabels, inputType: addCreditCardFormInputTypes.cZip }) }
      <input
        id="zip"
        type="text"
        value={ zip }
        className={ getInputClasses({ error: addCreditCardFormErrors.cZip, loading: getLoading({ loading, setupComplete: getewayStatus.setupComplete }) }) }
        onChange={ setZipValue }
        placeholder={ getPlaceHolders({ placeHolders: blueSnapSettings.placeHolders, placeHolderType: addCreditCardFormInputTypes.cZip }) }
        maxLength={ 30 }
      />
      { renderError(addCreditCardFormErrors.cZip) }
    </div>
  );

  const renderBlueSnapForm = () => (
    <div className={ classes.blueSnapFormContainer } style={ { ...blueSnapSettings.variables } }>
      { nameOfCardInput }
      { cardNumberInput() }
      { dateSecurityInput }
      { zipPostalCodeInput }
      { renderFraudFrame(getewayFields) }
    </div>
  );

  return renderBlueSnapForm();
};

export default AddCreditCardForm;

import { format } from 'date-fns';
import Bugsnag from '@bugsnag/js';
import { getDataFromStorage, saveDataToStorage } from './util';

const storageKey = 'logs_batch';
const maxRecords = 8000;

let tempLogs = [];

const saveLogToStorage = () => {
  if (tempLogs.length >= 20) {
    const storageLogs = getDataFromStorage(storageKey);
    let updatedArray = [...(JSON.parse(storageLogs) || []), ...tempLogs];
    if (updatedArray.length > maxRecords) {
      updatedArray =  updatedArray.slice(updatedArray.length - maxRecords);
    }
    saveDataToStorage(storageKey, JSON.stringify(updatedArray));
    tempLogs = [];
  }
};

const handleLog = (subsystem, message) => {
  const time = format(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS');
  const log = {
    time, subsystem, message
  };
  tempLogs.push(log);
  saveLogToStorage();
};

export const log = (subsystem, ...message) => {
  handleLog(subsystem, message);
  Bugsnag.leaveBreadcrumb(message);
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(subsystem, ...message);
  }
};

import { connect } from 'react-redux';
import TopBanner from './top_banner';
import withPGTranslation from '../../../config/withPGTranslation';

const mapStateToProps = ({
  user: { user }, application
}) => (
  {
    userBonusInfos: user && user.bonusInfos,
    anonymousUserBonusInfos: application && application.anonymousUser && application.anonymousUser.bonusInfos,
    userXfmProgram: user && user.xfmProgram,
    userId: user && user.id
  }
);

export default connect(
  mapStateToProps
)(withPGTranslation(TopBanner));

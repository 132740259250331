import React from 'react';
import queryString from 'query-string';
import  semver  from 'semver';
import classes from './classes.module.scss';
import { purchaseDetailsTypes } from '../config/const';
import PaymentMethodsListTitle from '../payment_methods_list/payment_methods_list_title';
import {
  getMinPrice, getAddCreditUrl, calculateRequiredFunds,
  fromMobileApp,
  getClientPlatformForAPI
} from '../config/utils';
import Toast from '../helpers/toast/toast';
import TopBanner from '../../app/components/header/top_banner';

const supportedApplePayVer = '5.4.0';

export const isVersionHigher = (version) => {
  const search = (queryString.parse(window.location.search) || {});
  const mobileVersion = search?.app_version;
  if (!mobileVersion) return false;

  return semver.gte(mobileVersion, version);
};

export function starSymbol(size, diff) {
  if (diff < getMinPrice()) return <b className={ classes.infoStar } style={ { '--size': size } }>*</b>;
  return null;
}

const getRequiredTitleForChat = ({ diffPrice, t }) => {
  if (!diffPrice) return null;
  return (diffPrice < getMinPrice() ? t('chat.minimum_balance_required') : t('chat.additional_funds_required'));
};

const renderRequiredTitlePayg = ({ t, isPayg, creditCardValidateError }) => {
  if (isPayg && creditCardValidateError) return null;
  return t('purchase.purchase_payment_information');
};

const requiredTitle = ({
  purchaseType, t, diffPrice, isPayg, creditCardValidateError
}) => {
  if (isPayg) return renderRequiredTitlePayg({ t, isPayg, creditCardValidateError });
  if (purchaseType === purchaseDetailsTypes.SIDE_MENU) return t('purchase.purchase_credit_side_menu');
  if (purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) return t('purchase.purchase_credit_affiliate_promo_popup_title');
  return getRequiredTitleForChat({ diffPrice, t });
};

export const renderPaymentMethodsListTitle = ({
  purchaseType, backArrowAction, hidePaymentDetails, theme, diffPrice, t, bonus,
  receivedMessageFromApp, setMessageToAppData, isPayg, creditCardValidateError, loading
}) => {
  const backArrowHandler = purchaseType === purchaseDetailsTypes.SIDE_MENU || purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP
    ? null : backArrowAction;
  const closeBtnHandler = purchaseType === purchaseDetailsTypes.SIDE_MENU || purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP
    ? hidePaymentDetails : null;
  return (
    <PaymentMethodsListTitle
      titleData={ {
        backArrowHandler,
        titleText: requiredTitle({
          purchaseType, t, diffPrice, bonus, isPayg, creditCardValidateError
        }),
        closeBtnHandler
      } }
      theme={ theme }
      receivedMessageFromApp={ receivedMessageFromApp }
      setMessageToAppData={ setMessageToAppData }
      loading={ loading }
    />
  );
};

export const renderdDetailsButton = ({
  purchaseType, setDetailsOpened, detailsOpened, t, isPayg
}) => {
  if (!purchaseType || isPayg) return null;
  if (purchaseType === purchaseDetailsTypes.SIDE_MENU) {
    return <div className={ classes.sideMenuBottom } />;
  }
  if (purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) {
    return null;
  }
  return (
    <button className={ classes.detailsButton } type="button" onClick={ () => { setDetailsOpened(!detailsOpened); } }>
      <p className={ classes.detailsButtonText }>{ detailsOpened ? t('chat.hide_details') : t('chat.show_details')}</p>
    </button>
  );
};

export const getPurchaseURL = ({ url, requiredCredit, purchaseType }) => {
  if (url) {
    const urlObject =  new URL(url, window.location.origin);
    const parsedQueryParams = queryString.parse(urlObject.search);
    let additionalQueryParams = { platform: getClientPlatformForAPI() };
    if (purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP || purchaseType === 'matchup_promo') {
      additionalQueryParams = { ...additionalQueryParams, is_affiliate_promo_purchase: true };
    }
    const updatedQueryParams = queryString.stringify({ ...parsedQueryParams, ...additionalQueryParams });
    return `${ urlObject.origin + urlObject.pathname  }?${ updatedQueryParams }`;
  }
  return getAddCreditUrl({ credit: requiredCredit, purchaseType });
};

export const renderAffiliationBanner = () => (<TopBanner />);

export const renderBoldPrice = ({
  purchaseType, diffPrice, isPayg
}) => {
  if (isPayg) return null;
  if (purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) {
    return renderAffiliationBanner();
  }

  if (!diffPrice) {
    return (
      <b className={ classes.priceTitle }>
        <div className={ classes.priceTitleSkeleton } />
      </b>
    );
  }
  return (
    <b className={ classes.priceTitle }>
      { `$${ (calculateRequiredFunds({ diffPrice })).toFixed(2) }` }
      { starSymbol(32, diffPrice) }
    </b>
  );
};

export const applePayIsSupported = () => {
  const supported = window.ApplePaySession && window.store.getState().purchaseDetails.canMakeApplePayPayments;
  if (fromMobileApp) {
    return isVersionHigher(supportedApplePayVer) && supported;
  }
  return supported;
};

export const renderFeesLabel = ({ purchaseFeesText, isPayg }) => {
  if (isPayg || !applePayIsSupported()) return null;
  return (
    <div className={ classes.purchaseFeesLable }>
      { purchaseFeesText }
    </div>
  );
};

export const renderToast = ({
  fees, showToast, isPayg, t
}) => {
  const { purchaseFeesText } = fees;
  let bottomPlacement = purchaseFeesText ? '215px' : '185px';
  bottomPlacement = isPayg ? '60px' : bottomPlacement;
  return (
    <Toast
      toastText={ t('purchase.default_payment_updated')  }
      show={ showToast }
      bottomPlacement={ bottomPlacement }
    />
  );
};

export const rendeExtraPriceLabel = ({
  options, price, bonus, t, purchaseType
}) => {
  const hasValidOptions = options && options.length > 0;
  const isAddingOption = hasValidOptions && options.find(e => !!e.adding);
  if (purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) return null;
  if (!hasValidOptions || !isAddingOption || (!bonus || bonus === 0)) return null;
  return (
    <div className={ classes.purchaseExtraPriceLabel }>
      { t('purchase.you_are_paying', { price: `${ price }` }) }
    </div>
  );
};
